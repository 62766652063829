/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import numeral from 'numeral';
import { Footer } from '../components/footer';
import { DashboardNavbar } from '../components/dashboard-navbar';
import { DashboardSidebar } from '../components/dashboard-sidebar';
import { useSettings } from '../contexts/settings-context';
import BackgroundImg from '../icons/illustrations/bg-img.svg';
import { getMerchantId, getSelectedBranch, isMerchantAdmin } from '../utils/merchant-info';
import { useMerchantContext } from '../contexts/merchant-context';
import UrgentAlert from '../components/uiComponents/AlertCard';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  paddingTop: 64
}));

const DashboardLayoutContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundImage: `url('${BackgroundImg}')`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  position: 'relative', // Add position relative to contain snowflakes
  '@keyframes fall': {
    '0%': {
      transform: 'translateY(0)'
    },
    '100%': {
      transform: 'translateY(100vh)'
    }
  }
}));

export const DashboardLayout = () => {
  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { settings, saveSettings } = useSettings();
  // eslint-disable-next-line no-unused-vars
  const merchantId = getMerchantId();
  const [merchantNotifications, setMerchantNotifications] = useState([]);
  const { merchantData, fetchMerchantData, isLoading } = useMerchantContext();
  const navigate = useNavigate();
  useEffect(async () => {
    if (!isLoading && !merchantData?.id) {
      await fetchMerchantData(merchantId);
    }
    if (merchantData?.notifications) {
      setMerchantNotifications(merchantData.notifications);
    }
  }, [merchantData?.id, merchantId]);

  const handlePinSidebar = () => {
    saveSettings({
      ...settings,
      pinSidebar: !settings.pinSidebar
    });
  };

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar />
      {!mdDown && (
        <DashboardSidebar
          onPin={handlePinSidebar}
          pinned={settings.pinSidebar}
        />
      )}
      <DashboardLayoutContent
        sx={{
          ml: {
            md: settings.pinSidebar ? '270px' : '73px'
          }
        }}
      >
        {merchantNotifications?.length > 0 && merchantNotifications.map((notification) => {
          const formattedMessage = () => {
            if (isMerchantAdmin) {
              return `Payment ${notification?.message.status} for ${getSelectedBranch()?.currency?.code} ${numeral(notification?.message.amountDue).format('0,0')}.`;
            }
            return `Payment ${notification?.message.status} Please make payment before your account auto suspends.`;
          };

          const isBillingNotificationValid = () => {
            if (notification?.key === 'billing') {
              const dueDate = new Date(notification?.message.dueDate);
              const today = new Date();
              return dueDate < today;
            }
            return true;
          };

          if (!isBillingNotificationValid()) {
            return null;
          }
          return (
            <UrgentAlert
              message={formattedMessage()}
              actionText={notification?.key === 'billing' ? 'Click Here To Pay' : 'View'}
              onActionClick={() => navigate(`/${notification?.key}`)}
              variant={notification?.key === 'billing' && notification?.message.status === 'Upcoming' ? 'warning' : 'error'}
            />
          );
        })}

        <Outlet />
        <Footer />
      </DashboardLayoutContent>
    </DashboardLayoutRoot>
  );
};
