/* eslint-disable max-len */
import React, { useState, useCallback, useEffect } from 'react';
import { Box, IconButton, Skeleton } from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
// import { Trash } from '../icons/trash';
import { ImageDropzone } from './image-dropzone';
import { XCircle } from '../icons/x-circle';
import { isMerchantAdmin } from '../utils/merchant-info';
import { useMerchantContext } from '../contexts/merchant-context';

const MerchantNavigationLogo = () => {
  const { merchantData, fetchMerchantData, merchantLogo, isLoading, updatedMerchantData, deleteMerchantLogo } = useMerchantContext();
  const [logoHovered, setLogoHovered] = useState(false);

  const loggedInUser = window.localStorage.getItem('user');
  const user = JSON.parse(loggedInUser);
  // const cookies = new Cookies();
  // console.log(user);
  // console.log(cookies.get('token'));
  const params = useParams();
  const merchantId = params.merchantId ? params.merchantId : user.companyId;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && merchantData === null) {
      fetchMerchantData(merchantId);
    }
    if (!isLoading && merchantData?.suspended) {
      const currentPath = window.location.pathname;
      console.log('User Permissions: ', isMerchantAdmin());
      if (!currentPath.startsWith('/billing') && !currentPath.startsWith('/payment') && !currentPath.startsWith('/suspended') && isMerchantAdmin()) {
        navigate('/suspended');
      }
    }
  }, [isLoading, merchantData?.id, fetchMerchantData, merchantId, merchantLogo]);
  // eslint-disable-next-line no-unused-vars
  const patchRequest = (result) => {
    if (result === null) {
      toast.error('Error updating merchant logo: No image selected.');
      return;
    }
    updatedMerchantData('base64Image', result, merchantId);
  };
  const convertToBase64 = (file) => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result.split(',')[1]);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
  const handleLogoUpload = useCallback((acceptedFiles) => {
    console.log(acceptedFiles);
    const file = acceptedFiles[0];
    convertToBase64(file)
      .then((result) => {
        patchRequest(result);
      }).catch((error) => {
        console.log(error.message);
        toast.error('Error updating merchant logo: ', error.message);
      });
  }, [merchantData, isLoading]);

  const handleMouseEnter = () => {
    setLogoHovered(true);
  };

  const handleMouseLeave = () => {
    setLogoHovered(false);
  };
  const handleDeleteLogo = () => {
    deleteMerchantLogo(merchantId);
  };

  if (isLoading || !user) {
    return (
      <Skeleton
        sx={{
          height: '4rem',
          width: '100%',
          minHeight: '3rem',
        }}
        variant="rectangular"
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '4rem',
        width: '100%',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {(merchantLogo) ? (
        <>
          <img
            alt="Logo"
            src={merchantLogo}
            style={{
              maxWidth: '80%',
              height: '4rem',
              objectFit: 'contain',
            }}

          />
          {logoHovered && (
            <IconButton
              size="small"
              color="primary"
              style={{ position: 'absolute', top: 4, right: 4 }}
              onClick={handleDeleteLogo}
            >
              <XCircle size={16} />
            </IconButton>
          )}
        </>

      ) : (
        <ImageDropzone
          onDrop={handleLogoUpload}
          accept={'image/*'}
          sx={{
            height: '4rem',
            display: 'web'
          }}
        />
      )}
    </Box>
  );
};

export default MerchantNavigationLogo;
