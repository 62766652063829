/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Grid,
  Paper,
  Link,
  Divider,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { AcUnit } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import { useTheme } from '@emotion/react';
import { useSettings } from '../contexts/settings-context';
import { LoginJwt } from '../components/auth/login-jwt';
import gtm from '../lib/gtm';
import loginIllustration from '../icons/illustrations/login-img.jpg';

// eslint-disable-next-line no-unused-vars
const Snowflake = styled('div')(({ theme }) => ({
  color: 'white',
  fontSize: `${Math.random() * 1.5 + 0.5}em`,
  fontFamily: 'Arial, sans-serif',
  position: 'absolute',
  opacity: Math.random(),
  left: `${Math.random() * 100}%`,
  top: `${Math.random() * 20}%`,
  animation: 'fall 10s linear infinite',
  animationDelay: `${Math.random() * 4}s`,
  zIndex: 1
}));

export const Login = () => {
  const { settings } = useSettings();
  const [snowflakes, setSnowflakes] = useState([]);
  const theme = useTheme();
  const isSmAndBelow = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    gtm.push({ event: 'page_view' });
    // Generate a more dynamic number of snowflakes
    const snowflakeCount = Math.floor(Math.random() * 75) + 25;
    setSnowflakes(Array.from({ length: snowflakeCount }));
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | Rack Dashboard</title>
      </Helmet>
      <Box>
        <Grid
          container
          sx={{
            height: '100vh',
            position: 'relative',
            overflow: 'hidden', // Ensure snowflakes are contained
            '@keyframes fall': {
              '0%': {
                transform: 'translateY(-10vh)', // Start slightly above the container
                opacity: 1
              },
              '100%': {
                transform: 'translateY(100vh)', // Fall to bottom of container
                opacity: 0
              }
            }
          }}
        >
          {!isSmAndBelow && (
            <Grid
              item
              md={6}
              xs={12}
            >
              <Paper
                sx={{
                  position: 'relative',
                  borderRadius: '0px',
                  width: '100%',
                  minHeight: '100vh',
                  background: `url(${loginIllustration})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  overflowY: 'hidden',
                  padding: '40px'
                }}
              />
              {/* {snowflakes.map((_, index) => (
                <Snowflake key={index}>
                  <AcUnit />
                </Snowflake>
              ))} */}
              <Typography
                fontSize="small"
                sx={{
                  position: 'absolute',
                  bottom: '20px',
                  left: '20px',
                  color: '#868998'
                }}
              >
                © Copyright. RACK. All Rights Reserved
              </Typography>
            </Grid>
          )}
          <Grid
            item
            md={6}
            xs={12}
          >
            <Paper
              sx={{
                borderRadius: '0px',
                width: '100%',
                minHeight: '100vh',
                background: `linear-gradient(-45deg, ${settings.theme === 'light' ? '#ffffff' : '#111318'} 50%, #f26633 50.3%)`,
                overflowY: 'hidden',
                padding: '40px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  marginBottom: '60px'
                }}
              >
                <LoginJwt />
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                item
                xs={12}
              >
                <Link
                  color="textSecondary"
                  href="http://www.rack.business"
                  target="_blank"
                  variant="body2"
                  mr={1}
                >
                  Rack website
                </Link>
                <Divider
                  color="textSecondary"
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginRight: 1,
                    background: '#868998',
                    width: '1.9px'
                  }}
                />
                <Link
                  color="textSecondary"
                  href="https://www.rack.business/privacy-policy/"
                  target="_blank"
                  variant="body2"
                  mr={1}
                >
                  Privacy Policy
                </Link>
                <Divider
                  color="textSecondary"
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginRight: 1,
                    background: '#868998',
                    width: '1.9px'
                  }}
                />
                <Link
                  color="textSecondary"
                  href="https://www.rack.business/privacy-policy/"
                  target="_blank"
                  variant="body2"
                  mr={1}
                >
                  Terms & Conditions
                </Link>
                <Divider
                  color="textSecondary"
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginRight: 1,
                    background: '#868998',
                    width: '1.9px'
                  }}
                />
                <Link
                  color="textSecondary"
                  href="http://www.rack.business"
                  target="_blank"
                  variant="body2"
                >
                  Contact Us
                </Link>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
